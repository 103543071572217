import {Injectable} from '@angular/core';
import {HttpService} from '@pwc-ecobonus/common';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TooltipService extends HttpService<any, any> {

  protected baseUrl: string = environment.apiUrl;
  protected endpoint: string = 'tooltip_dictionary';

  tooltips: Map<string, string> = null;

  public getTooltip(): Observable<any> {
    if (this.tooltips != null) {
      return of(this.tooltips);
    } else{
      return this.http.get(`${this.baseUrl}/${this.endpoint}`)
        .pipe(
          tap((data: any) => {
            this.tooltips = data.content;
            //return (this.tooltips);
          }),
          catchError(err =>
            this.tooltips = new Map()
          )
        );

    }

  }

  convertResourceToModel(resource: any) {
    return {...resource};
  }

  convertModelToResource(model: any) {
    return {...model};
  }

}
