export enum AuthorityCodeEnum {

  PWC_SUPER_USER = 'PWC_SUPER_USER',

  ROLE_ECOBONUS_TAX_CREDIT_READ = 'ROLE_ECOBONUS_TAX_CREDIT_READ',
  ROLE_ECOBONUS_TAX_CREDIT_CREATE = 'ROLE_ECOBONUS_TAX_CREDIT_CREATE',
  ROLE_ECOBONUS_TAX_CREDIT_UPDATE = 'ROLE_ECOBONUS_TAX_CREDIT_UPDATE',
  ROLE_ECOBONUS_TAX_CREDIT_DELETE = 'ROLE_ECOBONUS_TAX_CREDIT_DELETE',

  ROLE_ECOBONUS_DASHBOARD_READ = 'ROLE_ECOBONUS_DASHBOARD_READ',

  ROLE_ECOBONUS_CONFIG_READ = 'ROLE_ECOBONUS_CONFIG_READ',
  ROLE_ECOBONUS_CONFIG_CREATE = 'ROLE_ECOBONUS_CONFIG_CREATE',
  ROLE_ECOBONUS_CONFIG_UPDATE = 'ROLE_ECOBONUS_CONFIG_UPDATE',
  ROLE_ECOBONUS_CONFIG_DELETE = 'ROLE_ECOBONUS_CONFIG_DELETE',

  ROLE_ECOBONUS_DOSSIER_READ = 'ROLE_ECOBONUS_DOSSIER_READ',
  ROLE_ECOBONUS_DOSSIER_CREATE = 'ROLE_ECOBONUS_DOSSIER_CREATE',
  ROLE_ECOBONUS_DOSSIER_UPDATE = 'ROLE_ECOBONUS_DOSSIER_UPDATE',
  ROLE_ECOBONUS_DOSSIER_DELETE = 'ROLE_ECOBONUS_DOSSIER_DELETE',
  ROLE_ECOBONUS_DOSSIER_VALIDATE = 'ROLE_ECOBONUS_DOSSIER_VALIDATE',
  ROLE_ECOBONUS_DOSSIER_WORKABLE_PRACTICE = 'ROLE_ECOBONUS_DOSSIER_WORKABLE_PRACTICE',

  ROLE_ECOBONUS_PROJECT_READ = 'ROLE_ECOBONUS_PROJECT_READ',
  ROLE_ECOBONUS_PROJECT_CREATE = 'ROLE_ECOBONUS_PROJECT_CREATE',
  ROLE_ECOBONUS_PROJECT_UPDATE = 'ROLE_ECOBONUS_PROJECT_UPDATE',
  ROLE_ECOBONUS_PROJECT_DELETE = 'ROLE_ECOBONUS_PROJECT_DELETE',
  ROLE_ECOBONUS_PROJECT_ASSIGN = 'ROLE_ECOBONUS_PROJECT_ASSIGN',

  ROLE_ECOBONUS_COMMUNICATION_READ = 'ROLE_ECOBONUS_COMMUNICATION_READ',
  ROLE_ECOBONUS_COMMUNICATION_UPDATE = 'ROLE_ECOBONUS_COMMUNICATION_UPDATE',
  ROLE_ECOBONUS_COMMUNICATION_CHANGE_STATE = 'ROLE_ECOBONUS_COMMUNICATION_CHANGE_STATE',
  ROLE_ECOBONUS_COMMUNICATION_TRANSMISSION = 'ROLE_ECOBONUS_COMMUNICATION_TRANSMISSION',
  ROLE_ECOBONUS_COMMUNICATION_DELETE = 'ROLE_ECOBONUS_COMMUNICATION_DELETE',
  ROLE_ECOBONUS_COMMUNICATION_EXPORT = 'ROLE_ECOBONUS_COMMUNICATION_EXPORT',
  ROLE_ECOBONUS_COMMUNICATION_PROFIS = 'ROLE_ECOBONUS_COMMUNICATION_PROFIS',

  ROLE_ECOBONUS_DMS_VIEW = 'ROLE_ECOBONUS_DMS_VIEW',

  ROLE_ECOBONUS_ENTITIES_IMPORTER = 'ROLE_ECOBONUS_ENTITIES_IMPORTER',

  ROLE_ECOBONUS_NOTIFICATION_READ = 'ROLE_ECOBONUS_NOTIFICATION_READ',

  ROLE_ECOBONUS_REGISTRY_CREATE = 'ROLE_ECOBONUS_REGISTRY_CREATE',
  ROLE_ECOBONUS_REGISTRY_READ = 'ROLE_ECOBONUS_REGISTRY_READ',
  ROLE_ECOBONUS_REGISTRY_UPDATE = 'ROLE_ECOBONUS_REGISTRY_UPDATE',
  ROLE_ECOBONUS_REGISTRY_DELETE = 'ROLE_ECOBONUS_REGISTRY_DELETE',

  ROLE_ECOBONUS_ACCESS = 'ROLE_ECOBONUS_ACCESS',
  ROLE_ECOBONUS_B2C_ACCESS = 'ROLE_ECOBONUS_B2C_ACCESS',
  ROLE_ECOBONUS_B2B_DELIVERY = 'ROLE_ECOBONUS_B2B_DELIVERY',
  ROLE_ECOBONUS_ADDITIONAL_INFO_MANAGER = "ROLE_ECOBONUS_ADDITIONAL_INFO_MANAGER",

  ROLE_ECOBONUS_OWNERSHIP_PWC_CREATE = 'ROLE_ECOBONUS_OWNERSHIP_PWC_CREATE',
  ROLE_ECOBONUS_OWNERSHIP_PWC_READ = 'ROLE_ECOBONUS_OWNERSHIP_PWC_READ',
  ROLE_ECOBONUS_OWNERSHIP_PWC_UPDATE = 'ROLE_ECOBONUS_OWNERSHIP_PWC_UPDATE',
  ROLE_ECOBONUS_OWNERSHIP_PWC_DELETE = 'ROLE_ECOBONUS_OWNERSHIP_PWC_DELETE',

  ROLE_ECOBONUS_OWNERSHIP_TECH_CREATE = 'ROLE_ECOBONUS_OWNERSHIP_TECH_CREATE',
  ROLE_ECOBONUS_OWNERSHIP_TECH_READ = 'ROLE_ECOBONUS_OWNERSHIP_TECH_READ',
  ROLE_ECOBONUS_OWNERSHIP_TECH_UPDATE = 'ROLE_ECOBONUS_OWNERSHIP_TECH_UPDATE',
  ROLE_ECOBONUS_OWNERSHIP_TECH_DELETE = 'ROLE_ECOBONUS_OWNERSHIP_TECH_DELETE',

  ROLE_ECOBONUS_DELIVERY_EXPORT = 'ROLE_ECOBONUS_DELIVERY_EXPORT',
  ROLE_ECOBONUS_GDO_EXPORT = 'ROLE_ECOBONUS_GDO_EXPORT',

  ROLE_ECOBONUS_VALIDATION_RESET = 'ROLE_ECOBONUS_VALIDATION_RESET',
  ROLE_ECOBONUS_B2C_AMOUNT_EDITOR = 'ROLE_ECOBONUS_B2C_AMOUNT_EDITOR',
  ROLE_ECOBONUS_B2B_AMOUNT_EDITOR = 'ROLE_ECOBONUS_B2B_AMOUNT_EDITOR',

  ROLE_ECOBONUS_B2B_ADD_STAGE = 'ROLE_ECOBONUS_B2B_ADD_STAGE',
  ROLE_ECOBONUS_B2B_REMOVE_STAGE = 'ROLE_ECOBONUS_B2B_REMOVE_STAGE',
  ROLE_ECOBONUS_STAGE_DELETE_ALWAYS = 'ROLE_ECOBONUS_STAGE_DELETE_ALWAYS',

  // 500.59
  ROLE_ECOBONUS_DOSSIER_LEVEL1_ADD_STAGE = 'ROLE_ECOBONUS_DOSSIER_LEVEL1_ADD_STAGE',
  ROLE_ECOBONUS_DOSSIER_LEVEL1_REMOVE_STAGE = 'ROLE_ECOBONUS_DOSSIER_LEVEL1_REMOVE_STAGE',
  ROLE_ECOBONUS_DOSSIER_LEVEL1_REMOVE_STAGE_ADVANCED = 'ROLE_ECOBONUS_DOSSIER_LEVEL1_REMOVE_STAGE_ADVANCED',

  ROLE_ECOBONUS_REPORT_SENT_DELETE = 'ROLE_ECOBONUS_REPORT_SENT_DELETE',

  ROLE_ECOBONUS_TAX_ENGINE_ACCESS = 'ROLE_ECOBONUS_TAX_ENGINE_ACCESS',

  ROLE_ECOBONUS_TAX_ENGINE_CONFIRM_B2C_ACCESS = 'ROLE_ECOBONUS_TAX_ENGINE_CONFIRM_B2C_ACCESS',

  ROLE_ECOBONUS_TAX_ENGINE_TAX_CREDIT_PWC = 'ROLE_ECOBONUS_TAX_ENGINE_TAX_CREDIT_PWC',
  ROLE_ECOBONUS_DOSSIER_DELETE_ALWAYS = 'ROLE_ECOBONUS_DOSSIER_DELETE_ALWAYS',

  ROLE_ECOBONUS_ADVISORY_UPDATE = 'ROLE_ECOBONUS_ADVISORY_UPDATE',
  ROLE_ECOBONUS_ADVISORY_DELETE = 'ROLE_ECOBONUS_ADVISORY_DELETE',

  ROLE_ECOBONUS_B2B_GENERAZIONE_DS = 'ROLE_ECOBONUS_B2B_GENERAZIONE_DS',

  ROLE_ECOBONUS_PROJECT_HISTORY_ACCESS = 'ROLE_ECOBONUS_PROJECT_HISTORY_ACCESS',
  ROLE_ECOBONUS_MANAGER_HISTORY = 'ROLE_ECOBONUS_MANAGER_HISTORY',
  ROLE_ECOBONUS_PARTIAL_HISTORY = 'ROLE_ECOBONUS_PARTIAL_HISTORY',

  ROLE_ECOBONUS_DIONAS_EXPORT = 'ROLE_ECOBONUS_DIONAS_EXPORT',

  ROLE_ECOBONUS_ATD_WEB_EXPORT = 'ROLE_ECOBONUS_ATD_WEB_EXPORT',

  ROLE_ECOBONUS_PROJECT_DETACH = 'ROLE_ECOBONUS_PROJECT_DETACH',
  ROLE_ECOBONUS_PROJECT_ADD_RELATED = 'ROLE_ECOBONUS_PROJECT_ADD_RELATED',
  ROLE_ECOBONUS_ESCO_EXPORT = 'ROLE_ECOBONUS_ESCO_EXPORT',

  ROLE_ECOBONUS_ADVISORY_EXPORT = 'ROLE_ECOBONUS_ADVISORY_EXPORT',

  ROLE_UPDATE_TAX_OPERATOR = 'ROLE_UPDATE_TAX_OPERATOR',

  ROLE_ECOBONUS_TXE_SUPERVISOR_MEDIUM = 'ROLE_ECOBONUS_TXE_SUPERVISOR_MEDIUM',
  ROLE_ECOBONUS_TXE_SUPERVISOR_HIGH = 'ROLE_ECOBONUS_TXE_SUPERVISOR_HIGH',

  ROLE_ECOBONUS_DERIVED_PROJECTS_CREATE = 'ROLE_ECOBONUS_DERIVED_PROJECTS_CREATE',

  ROLE_ECOBONUS_PROJECT_CLOSE_RESET = 'ROLE_ECOBONUS_PROJECT_CLOSE_RESET',
  ROLE_ECOBONUS_DOSSIER_CLOSE_RESET = 'ROLE_ECOBONUS_DOSSIER_CLOSE_RESET',

  ROLE_ECOBONUS_HELP_DESK_FAQ = 'ROLE_ECOBONUS_HELP_DESK_FAQ',
  ROLE_ECOBONUS_HELP_DESK_SUPPORT_REQUESTS = 'ROLE_ECOBONUS_HELP_DESK_SUPPORT_REQUESTS',
  ROLE_ECOBONUS_HELP_DESK_SUPPORT_PROJECT = 'ROLE_ECOBONUS_HELP_DESK_SUPPORT_PROJECT',
  ROLE_ECOBONUS_HELP_DESK_SUPPORT_REQUESTS_EXPORT = 'ROLE_ECOBONUS_HELP_DESK_SUPPORT_REQUESTS_EXPORT',

  ROLE_ECOBONUS_DELEGATE_DELETE = 'ROLE_ECOBONUS_DELEGATE_DELETE',
  ROLE_ECOBONUS_OWNERSHIP_READ = 'ROLE_ECOBONUS_OWNERSHIP_READ',

  ROLE_ECOBONUS_REPORT_TECH = 'ROLE_ECOBONUS_REPORT_TECH',

  ROLE_ECOBONUS_CARIGE_EXPORT = 'ROLE_ECOBONUS_CARIGE_EXPORT',

  // PRICING TABLE
  ROLE_ECOBONUS_PRICING_TABLE_PROJECT = 'ROLE_ECOBONUS_PRICING_TABLE_PROJECT',

  ROLE_ECOBONUS_BLOCK_PROJECT = 'ROLE_ECOBONUS_BLOCK_PROJECT',
  ROLE_ECOBONUS_UNLOCK_PROJECT = 'ROLE_ECOBONUS_UNLOCK_PROJECT',
  ROLE_ECOBONUS_BLACKLIST_VIEW = 'ROLE_ECOBONUS_BLACKLIST_VIEW',

  ROLE_ECOBONUS_TEAM_DELIVERY = 'ROLE_ECOBONUS_TEAM_DELIVERY',

  // PDC
  ROLE_ECOBONUS_PDC_READ = 'ROLE_ECOBONUS_PDC_READ',
  ROLE_ECOBONUS_PDC_ARCHIVE = 'ROLE_ECOBONUS_PDC_ARCHIVE',
  ROLE_ECOBONUS_PDC_REPLACE = 'ROLE_ECOBONUS_PDC_REPLACE',
  ROLE_ECOBONUS_PDC_DELETE = 'ROLE_ECOBONUS_PDC_DELETE',
  ROLE_ECOBONUS_PDC_FORCE_GENERATE = 'ROLE_ECOBONUS_PDC_FORCE_GENERATE',
  ROLE_ECOBONUS_PDC_INVALIDATE_GENERATION = 'ROLE_ECOBONUS_PDC_INVALIDATE_GENERATION',

  //CHECKLIST DOCUMENT
  ROLE_ECOBONUS_CHECKLIST_DOCUMENT_UPDATE = 'ROLE_ECOBONUS_CHECKLIST_DOCUMENT_UPDATE',
  ROLE_ECOBONUS_CHECKLIST_DOCUMENT_READ = 'ROLE_ECOBONUS_CHECKLIST_DOCUMENT_READ',
  ROLE_ECOBONUS_CHECKLIST_MASSIVE_VALIDATION = 'ROLE_ECOBONUS_CHECKLIST_MASSIVE_VALIDATION',
  ROLE_ECOBONUS_DOCUMENT_UPLOAD_DEROGATION = "ROLE_ECOBONUS_DOCUMENT_UPLOAD_DEROGATION",

  ROLE_ECOBONUS_DOCUMENTS_ELIGIBILITY = 'ROLE_ECOBONUS_DOCUMENTS_ELIGIBILITY',

  ROLE_ECOBONUS_TAX_ENGINE_REGENERATE_REPORT = 'ROLE_ECOBONUS_TAX_ENGINE_REGENERATE_REPORT',
  ROLE_ECOBONUS_CUSTOMER_INFORMATIVE_CONFIGURATION = 'ROLE_ECOBONUS_CUSTOMER_INFORMATIVE_CONFIGURATION',
  ROLE_ECOBONUS_INTEGRATION_DASHBOARD_ACCESS = 'ROLE_ECOBONUS_INTEGRATION_DASHBOARD_ACCESS',

  ROLE_ECOBONUS_CREATE_PREMIUM_CUSTOMER_CONFIGURATION = 'ROLE_ECOBONUS_CREATE_PREMIUM_CUSTOMER_CONFIGURATION',
  ROLE_ECOBONUS_UPDATE_PREMIUM_CUSTOMER_CONFIGURATION = 'ROLE_ECOBONUS_UPDATE_PREMIUM_CUSTOMER_CONFIGURATION',

  // Chatbot roles
  ROLE_B2B_CHATBOT_VIEW = 'ROLE_B2B_CHATBOT_VIEW',
  ROLE_B2B_CHATBOT_SWITCH_MODEL = 'ROLE_B2B_CHATBOT_SWITCH_MODEL',

  ROLE_B2B_TAX_ENGINE_CUSTOM_TEMPLATE = 'ROLE_B2B_TAX_ENGINE_CUSTOM_TEMPLATE',

  ROLE_ECOBONUS_TXE_BYPASS_SEND_REPORT_DISABLING = 'ROLE_ECOBONUS_TXE_BYPASS_SEND_REPORT_DISABLING',

  // Live Assistant roles
  ROLE_ECOBONUS_VC_READ = 'ROLE_ECOBONUS_VC_READ',
  ROLE_ECOBONUS_VC_READ_ALL = 'ROLE_ECOBONUS_VC_READ_ALL',
  ROLE_ECOBONUS_VC_READ_MYDATA = 'ROLE_ECOBONUS_VC_READ_MYDATA',
  ROLE_ECOBONUS_VC_CREATE = 'ROLE_ECOBONUS_VC_CREATE',
  ROLE_ECOBONUS_VC_UPDATE = 'ROLE_ECOBONUS_VC_UPDATE',
  ROLE_ECOBONUS_VC_DELETE = 'ROLE_ECOBONUS_VC_DELETE',

  // Wizard importer from B2B
  ROLE_ECOBONUS_WIZARD_IMPORTER_B2B = "ROLE_ECOBONUS_WIZARD_IMPORTER_B2B",

  ROLE_ECOBONUS_TAX_ENGINE_DIONAS_EXCLUSION = 'ROLE_ECOBONUS_TAX_ENGINE_DIONAS_EXCLUSION',
  ROLE_ECOBONUS_TAX_ENGINE_DIONAS_INCLUSION = 'ROLE_ECOBONUS_TAX_ENGINE_DIONAS_INCLUSION',
  ROLE_ECOBONUS_DERIVED_CHECKLIST_MASSIVE_SYNC = 'ROLE_ECOBONUS_DERIVED_CHECKLIST_MASSIVE_SYNC',
  ROLE_ECOBONUS_DERIVED_AMOUNTS_MASSIVE_IMPORT = 'ROLE_ECOBONUS_DERIVED_AMOUNTS_MASSIVE_IMPORT',

  ROLE_ECOBONUS_ADDITIONAL_INFO_VISTI_REGION_MANAGER = 'ROLE_ECOBONUS_ADDITIONAL_INFO_VISTI_REGION_MANAGER'
}
