import { Component, OnInit } from '@angular/core';
import { ApiResource, PageableResponse } from '@pwc-ecobonus/common';
import { BreadcrumbItem, SortDirection, SortEvent } from '@pwc-ecobonus/ui';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BaseComponent } from './base-component';

@Component({
  template: ''
})
export class BasePage extends BaseComponent implements OnInit {
  breadcrumbs: BreadcrumbItem[] = [];

  sortDirection: SortDirection = '';
  sortType: string;
  sortReverse: number = 0;

  searchTriggerSubject: Subject<void> = new Subject<void>();
  searchTrigger$: Observable<void> = this.searchTriggerSubject.asObservable();

  protected loadingSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);
  loading$: Observable<boolean> = this.loadingSubject.asObservable();

  pagination: PageableResponse<ApiResource[]>;
  currentPage = 0;
  pageSize = 10;

  ngOnInit(): void {
    this.initBreadcrumbs();
  }

  onSort($event: SortEvent): void {
    switch ($event.direction) {
      case 'asc':
        this.sortReverse = 1;
        break;
      case 'desc':
        this.sortReverse = 2;
        break;
      default:
        this.sortReverse = 0;
        break;
    }

    this.sortType = $event.column;
    this.sortDirection = $event.direction;

    this.onSearch();
  }

  public onSearch(): void {
    this.searchTriggerSubject.next();
  }

  onPageChange(page: number): void {
    this.currentPage = page - 1;
    this.onSearch();
  }

  onPageSizeChange(size: number): void {
    this.pageSize = size;
    this.onSearch();
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  initBreadcrumbs(): void {
  }

  public isEnterprise(): boolean {
    return environment.configuration.enterprise;
  }
}
