export enum RoutePath {
  SLASH = '/',
  LOGIN = 'login',
  NEW = 'new',
  EDIT = 'edit',
  CONFIG = 'config',
  VIEW = 'view',
  LOGIN_FAQ = 'login-faq',
  ACCESS_PROBLEM = 'access-problem',

  ADVISORY = 'advisory',
  CONFIGURATION = 'configuration',
  TEMPLATES = 'templates',
  DOCUMENTS = 'documents',
  FAQ = 'faq',
  FAQ_CATEGORY= 'faq-category',
  PRICING_TABLE = 'pricing-table',
  PHASES = 'phases',
  FAQ_LIST = 'faq-list',
  CHECKLIST_DOCUMENT = "checklist-document",

  /* Help Desk */
  SUPPORT_TYPE = 'support-type',
  SUPPORT_REQUEST = 'support-request',
  SUPPORT_REQUEST_LOGIN = 'support-request-login',
  SENT = 'sent',
  LIST = 'list',
  MY_LIST = 'my-list',

  /*  dossier */
  DOSSIER = 'operation',
  DOSSIER_DETAILS = 'details',
  FILLOUT = 'fillout',

  COMMUNICATION = 'communication',

  REPORTS = 'reports',

  PROJECT = 'project',

  DASHBOARD = 'dashboard',

  INTEGRATION_DASHBOARD = 'integration-dashboard',
  UNIT_INTEGRATION_DASHBOARD = 'unit-integration-dashboard',
  
  /* tax-credit */
  TAX_CREDIT = 'tax-credit',

  /* entities importer */
  ENTITIES_IMPORTER = 'entities-importer',
  IMPORTER_USER_ENTRIES = 'users',
  IMPORTER_AMOUNT_REMEDIATION_ENTRIES = 'amount-remediations',
  IMPORTER_PROJECT_OWNERSHIP_TAX_ENTRIES = 'project-ownership-tax',
  IMPORTER_PROJECT_MOL_ENTRIES = 'project-mol',
  IMPORTER_PROJECT_DELETION_ENTRIES = 'project-deletion',
  IMPORTER_PRICING_TABLE_ENTRIES = 'pricing-table',
  IMPORTER_UNSELECT_TAX_OWNER = 'tax-owner',
  IMPORTER_UNSELECT_TECHNICAL_OWNER = 'technical-owner',
  IMPORTER_BLOCK_UNLOCK_PROJECTS = 'block_unlock_projects',
  IMPORTER_BLACKLIST_BLOCK_UNLOCK = "blacklist_block_unlock",
  IMPORTER_CHECKLIST_DOCUMENT = 'checklist_document_entry',
  IMPORTER_DOCUMENTARY_CHECKLISTS = 'documentary_checklists_entry',
  IMPORTER_ASSIGN_REMOVE_INSPECTIONS = 'assign-remove-inspections',
  IMPORTER_BPER_USER_INTEGRATION = 'bper_user_integration',
  IMPORTER_BPER_PROJECT_CREATION = 'bper_project_creation',
  IMPORTER_BPER_PROJECT_COPY = 'bper_project_copy',
  IMPORTER_ASSIGN_DEROGATION = 'assign_derogation',
  IMPORTER_REMOVE_DEROGATION = 'remove_derogation',
  IMPORTER_DISABLE_USERS = 'disable-users',
  IMPORTER_PROJECT_OPEN = 'project-open',

  /* notification */
  NOTIFICATION = 'notifications',

  // Registry
  REGISTRY = 'registry',

  // Ownership
  OWNERSHIP = 'ownership',
  OWNERSHIP_PWC = 'pwc',
  OWNERSHIP_TECH = 'tech',

  // Questionnaire
  QUESTIONNAIRETEST = 'questionnaire-test',
  QUESTIONNAIRE = 'questionnaire',
  QUESTION_LIST = 'question-list',

  /* master-data */
  MASTER_DATA = 'master-data',
  REAL_ESTATE_UNIT = 'real-estate-unit',
  OWNER_DATA = 'owner-data',
  CONDOMINIUM = 'condominium',
  ADMINISTRATOR = 'administrator',
  ASSIGNOR = 'assignor',
  ASSIGNEE = 'assignee',
  SUPPLIER = 'supplier',
  BENEFICIARIES = 'beneficiaries',
  PROFESSIONALS = 'professionals',
  CONTACT_PERSON = 'contact-person',
  OPERATIONS = 'operations',

  REDIRECT = 'redirect',
  B2C = 'b2c',

  PRICING = 'pricing',
  TECHNICAL_PARTNER = 'technical-partner',
  TAX_ENGINE = 'tax-engine',

  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  PRIVACY_POLICY = 'privacy-policy',
  ACCEPT_PRIVACY_POLICY = 'accept-policy',

  TARIFF = 'tariff',

  TEAM_DELIVERY = 'team-delivery',

  // master - linked projects
  MASTER = 'master',
  CREATE = 'create',

  BLACKLIST = 'blacklist',

  PDC = 'pdc',
  EDOC = 'edoc',
  DOWNLOAD = 'download',

  SAL_YEAR = 'sal-year',
  HOLIDAY_SKIP = 'holiday-skip',

  CUSTOMER_INFORMATIVE = 'informative-cliente',
  CUSTOMER_POPUP = 'popup',
  CUSTOMER_BANNER = 'banner',
  HISTORY_ANONYMIZATION = 'history-anonymization',

  PREMIUM_CUSTOMER = 'premium-customer',
  
  // LIVE ASSISTANT
  LIVE_ASSISTANT = 'live-assistant',
  LIVE_ASSISTANT_CONFIGURATION = 'la-configuration',
  LIVE_ASSISTANT_MONITORING = 'la-monitoring',
  MY_VIDEOCALL = 'my-videocall',
  VIDEOCALL_CREATED = 'videocall-created',
  VIDEOCALL_HISTORY = 'videocall-history',
  LIVE_ASSISTANT_CALL = 'new-videocall',
  LIVE_ASSISTANT_JOIN = 'live-assistant-join'

}
