<ngb-accordion #accFaq="ngbAccordion">
  <ngb-panel id="faq-list_{{index}}" cardClass="accordion">

    <!-- Question (accordion header) -->
    <ng-template ngbPanelHeader>
      <div class="card">
        <div class="card-header d-flex align-items-center justify-content-between"
          (click)="accFaq.toggle('faq-list_'+index)">
          <h5 class="font-weight-bold text-color" translate>
            {{index + 1}}.
            {{faq.question}}
          </h5>
          <div *ngIf="!(accFaq.isExpanded('faq-list_'+index))">
            <img src="assets/images/icons/ic_arrow_bottom.svg">
          </div>
          <div *ngIf="accFaq.isExpanded('faq-list_'+index)">
            <img src="assets/images/icons/ic_arrow_top.svg">
          </div>
        </div>
      </div>

    </ng-template>

    <!-- Answer (accordion content) -->
    <ng-template ngbPanelContent>
      <div class="card card-body">
        <ckeditor #ckeditor [editor]="editor" [config]="editorConfig" [innerHTML]="faq.answer" [disabled]="true">
        </ckeditor>
      </div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>