import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { Faq } from '../models/faq';

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  protected faqEndpoint: string = "faq";

  constructor(private http: HttpClient) { }

  public search(faqResource: any) {
    return this.http.get(`${environment.apiUrl}/${this.faqEndpoint}/search`, { params: faqResource });
  }
  public detail(id: number) {

    return this.http.get(encodeURI(`${environment.apiUrl}/${this.faqEndpoint}/${id}`));
  }

  public update(faq: Faq) {
    return this.http.put(`${environment.apiUrl}/${this.faqEndpoint}`,faq);
  }

  public insert(faq: any) {
    return this.http.post(`${environment.apiUrl}/${this.faqEndpoint}`,faq);
  }

  public deleteFaq(id: number) {
    return this.http.delete(`${environment.apiUrl}/${this.faqEndpoint}/${id}`);
  }

  public getLoginFaqs(faqResource: any) {
    return this.http.get(`${environment.apiUrl}/${this.faqEndpoint}/login-faqs`, { params: faqResource });
  }

  public isValidTimeForSupportRequest(faqResource: any) {
    return this.http.get(`${environment.apiUrl}/${this.faqEndpoint}/isValidTimeForSupportRequest`, { params: faqResource });
  }
}
