export enum BankCode {
  B001 = 'B001',
  B002 = 'B002',
  B003 = 'B003',
  B004 = 'B004',
  B005 = 'B005',
  B006 = 'B006',
  B007 = 'B007',
  B008 = 'B008',
  B009 = 'B009',
}
