import { Level } from '@nsalaun/ng-logger';

export const baseEnvironment = {
  production: true,
  appVersion: require('../../package.json').version,
  cookieDomain: 'pwc-tls.it',
  cookiePath: '/',
  secureFlag: true,
  notificationInterval: 60,
  maxSizeUploadFile: 1024 * 1024 * 30,
  logLevel: Level.ERROR,
  configuration: {
    landRegistryMaxCharsValidators: {
      foglio: 5,
      subalterno: 4,
      particella: 5,
      particellaDenominatore: 4
    },
    showBranches: true,
    showCustomerType: true,
    deliveryCsv: false,
    projectExport: true,
    helpDeskEmail: 'it_pwc_ecobonus@pwc.com',
    manualFileName: 'Manuale utente - Piattaforma ECOBONUS.pdf',
    faqFileName: null,
    bankManagerInfo: {
      show: true,
      required: true
    },
    bank: false,
    bankCode: null,
    ownership: false,
    ownershipTax: false,
    practiceTypesLabelBase: '',
    phoneMandatory: false,
    creditTransferNoSal: false,
    enterprise: false, // piattaforma per mondo imprese
    region: false,
    ndg: false,
    pricing: false,
    showMaximumAmountInSal: false,
    maxSal: 8,
    maxSalWithSuperbonus: 8,
    disableAddStageForOnlyCes: false,
    showPrivacy: false,
    privacyLink: '',
    termsAndConditionsLink: '',
    projectLabelsBase: '',
    mandaroryThousands: false,
    convention: false,
    prescreeningTicket: false,
    lockDocumentValidationUntilSubmitted: true,
    advisory: false,
    delegate: {
      active: false
    },
    ds: false,
    dsStartDate1: new Date(2021, 5, 7, 0, 0, 0),
    dsStartDate2: null,
    dsStartDateSimplified: new Date(2021, 7, 2, 0, 0, 0),
    simplifiedProjects: false,
    simplifiedProjectsStartDate: new Date(2021, 5, 22, 0, 0, 0),
    finCesType: false,
    thresholdDate: new Date(2021, 7, 2, 0, 0, 0),
    massiveDownload: false,
    massiveValidation: true,
    massiveEligibilityValidation: true,
    employeePricing: false,
    communications: false,
    dsStartDateSimplifiedUCI: null,
    dynamicFaq: {
      active: false,
      showLoginBankField: false,
      showSocietyInEmailSubject: false
    },
    termsAndConditionFileName: '',
    preventStageChangeOnValidationKO: false,
    requireAddStageConfirmationFinCes: false,
    technicalCertification: false,
    sap: {
      active: false
    },
    blacklist: {
      active: false
    },
    inspection: {
      active: false
    },
    questionSimplifiedProjects: 'L’importo complessivo degli interventi è pari o inferiore ad euro 10.000 (IVA inclusa)?',
    projectStatisticalAmountFields: false,
    mandatoryNdg: false,
    condominiumCfRequiredCheckEnabled: false,

    taxEngineReportRegenerateAlert: 'Con tale azione si procede a rielaborare il Report per poter procedere ad una successiva Ri-emissione. Inoltre laddove si intenda effettuare delle modifiche al Progetto (INFORMAZIONI GENERALI, ANAGRAFICHE PROGETTO e DATI CONTABILI), è necessario effettuarle prima di RIELABORARE il REPORT. Vuoi confermare la rielaborazione?',
    hideAddBeneficiary: false,

    pdc: {
      active: false
    },

    b2bYearChecklistSalDisabled: false,

    documentsEligibilityEnabled: false,
    migratedProjectActive: false,
    migratedProjectLabelBase: '',
    allowMultiUploadActions: false,
    showSynchDates: false,
    showBenevisi: false,
    internalUseDocBanner: false,

    chatbot: {
      enabled: false,
      canChangeModeGlobally: false,
      chatbotLinkToReportEnabled: false,
      chatbotTos: '/assets/downloads/chatbot_tos.pdf',
      chatbotReportLink: 'https://docs.google.com/forms/d/e/1FAIpQLSfVqqYukb8kELm0EBLpAnZGOOoMj0GNdjPCtfxJAk2jvOsxOw/viewform?entry.872826190=',
      mockupModeEnabled: false,
      healthCheck: {
        enabled: false
      },
      rdaMaxMessages: 5,
      maxRetries: 2
    },
    customerInformativeConfiguration: false,
    showIdDossierCsv: false,
    showStageCsv: false,
    showPricingExport: false,
    workablePracticeEnabled: false,
    holidaySkipEnabled: false,
    liveAssistantActive: false,
    wizardImporter: true,
    liveAssistantKeepAliveRefreshTime: 300000, // 5 minutes
    leadingProjectExistance: {
      active: false,
      startDate: null
    },
    vistiRegionFlag: false,
    affectedBySismaFlagCutoffDate: new Date(2024, 5, 6, 0, 0, 0)
  },
  recaptcha: '6Lcm68gaAAAAADn75a2Qt7xxVQ8OpmAsl2W_3bPT',
  storageExpirationTtl: 20,
  enabledSSOOnlyForInternal: {
    active: false,
    domain: 'pwc.com'
  }
};
