import { Injectable, EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { FileUploader, FileUploaderOptions, FileItem, ParsedResponseHeaders } from '@pwc-ecobonus/forms';
import { AuthenticationService } from '@pwc-ecobonus/security';
import { Observable } from 'rxjs';
import { UploadFile } from '../components/upload-form/models/upload-file';
import { Logger } from '@nsalaun/ng-logger';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  protected baseUrl: string = environment.apiUrl;
  protected endpoint: string = '';

  uploader: FileUploader;
  onUploadCompleted: EventEmitter<void> = new EventEmitter<void>();
  onItemCompleted: EventEmitter<UploadFile> = new EventEmitter<UploadFile>();
  onAttachmentCompleted: EventEmitter<any> = new EventEmitter<any>();
  onItemError: EventEmitter<any> = new EventEmitter<any>();

  private uploaderOptions: FileUploaderOptions;
  private data: UploadFile;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private logger: Logger
  ) {
    const cookie = this.authenticationService.cookieValue;

    this.uploaderOptions = {
      url: `${environment.apiUrl}/${this.endpoint}`,
      authTokenHeader: 'Authorization',
      authToken: `Bearer ${cookie.access_token}`
    };

    this.uploader = new FileUploader(this.uploaderOptions);
  }

  public setEndpoint(endpoint) {
    this.endpoint = endpoint;
    this.uploaderOptions.url = `${this.baseUrl}/${this.endpoint}`;
  }

  public deleteAttachment(elementId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${this.endpoint}/${elementId}`).pipe();
  }

  public setData(data: any): this {
    this.data = data;
    return this;
  }

  public upload() {
    if (!this.uploader.queue.length) {
      this.logger.warn('UploaderService: uploader queue is empty.');
      return;
    }

    // Refresh uploader options to ensure access_token is correctly set.
    this.refreshOptions();

    //  Set custom items data.
    this.setItemsData();

    // Prevent authorization errors.
    this.uploader.onBeforeUploadItem = (item: FileItem) => {
      item.withCredentials = false;
      item.formData = this.data;
      return item;
    };

    this.uploader.onCompleteItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any => {
      this.logger.debug({ item, response, status, headers });

      if (status === 200) {
        const res = JSON.parse(response) as UploadFile;
        this.logger.debug(res);
        this.onItemCompleted.emit(res);
      } else {
        const res = JSON.parse(response != null && response !== '' ? response : '{}');
        this.onItemError.emit(res)
        item.headers = { 'error_type': res.type };
      }
      let attachment: any = JSON.parse(response);
      this.onAttachmentCompleted.emit(attachment);
      return { item, response, status, headers };
    };

    this.uploader.onCompleteAll = () => {
      this.onUploadCompleted.emit();
    };

    this.logger.debug('Upload all items');
    //  Upload all queued items.
    this.uploader.uploadAll();
  }

  public removePending(): void {
    if (!this.uploader.queue.length) {
      return;
    }

    const toRemove = this.uploader.queue.filter((item: FileItem) => !item.isSuccess && !item.isError);

    for (const item of toRemove) {
      this.uploader.removeFromQueue(item);
    }
  }

  public removeAll(): void {
    this.uploader.queue = [];
  }

  get pendingItemsCount(): number {
    return this.uploader.getNotUploadedItems().length;
  }

  get totalItemsCount(): number {
    return this.uploader.queue.length;
  }

  get completedItemsCount(): number {
    return this.totalItemsCount - this.pendingItemsCount;
  }

  get isUploading(): boolean {
    return this.uploader.isUploading;
  }

  get isPending(): boolean {
    return !this.isUploading && this.pendingItemsCount > 0;
  }

  get progress(): number {
    return this.uploader.progress;
  }

  get hasItems(): boolean {
    return this.totalItemsCount > 0;
  }

  get isError(): boolean {
    return this.hasItems && !this.isUploading && this.uploader.queue.filter((item: FileItem) => item.isError).length > 0;
  }

  get isSuccess(): boolean {
    return this.hasItems && !this.isPending && !this.isUploading && !this.isError;
  }

  private refreshOptions() {
    const cookie = this.authenticationService.cookieValue;

    // Ensure the correct auth token is set in the request.
    this.uploaderOptions.authToken = `Bearer ${cookie.access_token}`;

    this.uploader.setOptions(this.uploaderOptions);
  }

  private setItemsData() {
    this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
      if (fileItem.formData != null && (!Array.isArray(fileItem.formData) || fileItem.formData.length !== 0)) {
        const data = fileItem.formData;

        form.append('elementId', data.elementId || 0);
        if (data.trainingDocumentTypeId) {
          form.append('trainingDocumentTypeId', data.trainingDocumentTypeId || 0);
        }
        if (data.name) {
          form.append('name', data.name || '');
        }
        return { fileItem, form };
      }
    };
  }

}
