import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {catchError, tap} from 'rxjs/operators';
import {ToastUtils} from "../utils/toast-utils";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor{

  constructor(private toast: ToastrService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.error?.type === 'project_blocked') {
            this.toast.error('Non è consentito effettuare tale operazione. Il progetto è stato bloccato da PwC.', ToastUtils.TITLE_ERROR);
            return of(null);
          } else {
            throw err;
          }
        }
        throw err;
      }));
  }
}
