import { baseEnvironment } from './base.environment';

export const bankEnvironment = {
  ...baseEnvironment,
  configuration: {
    ...baseEnvironment.configuration,
    faqFileName: 'FAQ_Piattaforma Ecobonus.pdf',
    dynamicFaq: {
      ...baseEnvironment.configuration.dynamicFaq,
      active: true,
      showLoginBankField: false
    },
    sap: {
      ...baseEnvironment.configuration.sap,
      active: true
    },
    blacklist: {
      ...baseEnvironment.configuration.blacklist,
      active: true
    },
    pdc: {
      active: true
    },
    inspection: {
      active: true
    },
    b2bYearChecklistSalDisabled: true,
    documentsEligibilityEnabled: true,
    showSynchDates: false,

    chatbot: {
      enabled: true,
      canChangeModeGlobally: false,
      chatbotLinkToReportEnabled: false,
      chatbotTos: '/assets/downloads/chatbot_tos.pdf',
      chatbotReportLink: 'https://docs.google.com/forms/d/e/1FAIpQLSfVqqYukb8kELm0EBLpAnZGOOoMj0GNdjPCtfxJAk2jvOsxOw/viewform?entry.872826190=',
      mockupModeEnabled: false,
      healthCheck: {
        enabled: true
      },
      rdaMaxMessages: 5,
      maxRetries: 2
    },
    customerInformativeConfiguration: true,
    workablePracticeEnabled: true,
    vistiRegionFlag: true
  },
  enabledSSOOnlyForInternal: {
    active: true,
    domain: 'pwc.com'
  }
};
