import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { ChatbotComponent } from './chatbot.component';
import { MessagesListComponent } from './components/messages-list/messages-list.component';
import { MessageDetailComponent } from './components/message-detail/message-detail.component';
import { SubmissionFormComponent } from './components/submission-form/submission-form.component';
import { PwcIconsModule } from '@pwc-ecobonus/icons';
import {
  ChatbotTypingIndicatorComponent
} from './components/chatbot-typing-indicator/chatbot-typing-indicator.component';
import { UIModule } from '@pwc-ecobonus/ui';

/**
 * This module is used to manage the chatbot.
 * It contains the chatbot component and its sub-components.
 *
 * @author Mc Ayrton Aragon
 */
@NgModule({
  declarations: [
    ChatbotComponent,
    MessagesListComponent,
    MessageDetailComponent,
    SubmissionFormComponent,
    ChatbotTypingIndicatorComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    NgbCollapseModule,
    PwcIconsModule,
    UIModule
  ],
  exports: [
    ChatbotComponent,
    MessagesListComponent,
    MessageDetailComponent,
    SubmissionFormComponent
  ]
})
export class ChatbotModule {
}
