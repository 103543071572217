// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Level } from '@nsalaun/ng-logger';
import { bankEnvironment } from './bank.environment';
import { BankCode } from '../app/enums/bank-code.enum';
import { baseEnvironment } from './base.environment';

export const environment = {
  ...bankEnvironment,
  production: false,
  authUrl: 'https://backend.ecobonus-dev2.digitalsuite.pwc-tls.it',
  apiUrl: 'https://ecobonus-backend.ecobonus-dev2.digitalsuite.pwc-tls.it',
  // frameworkUrl: 'https://ecobonus-dev2.digitalsuite.pwc-tls.it',
  frameworkUrl: 'https://ecobonus.ecobonus-dev2.digitalsuite.pwc-tls.it/auth',
  dmsUrl: 'https://dms.ecobonus-dev2.digitalsuite.pwc-tls.it',
  logLevel: Level.DEBUG,
  configuration: {
    ...bankEnvironment.configuration,
    showBranches: false,
    showCustomerType: false,
    phoneMandatory: true,
    helpDeskEmail: 'assistenza@eaglewise.it',
    manualFileName: 'b003/Manuale utente - Piattaforma ECOBONUS.pdf',
    faqFileName: 'b001/FAQ_Piattaforma Ecobonus.pdf',
    pricing: false,
    maxSal: 8,
    maxSalWithSuperbonus: 2,
    disableAddStageForOnlyCes: true,
    bank: true,
    bankCode: BankCode.B001,
    ownership: true,
    ownershipTax: true,
    convention: true,
    advisory: true,
    delegate: {
      ...bankEnvironment.configuration.delegate,
      active: true,
    },
    dynamicFaq: {
      active: true,
      showLoginBankField: true,
      showSocietyInEmailSubject: true
    },
    ds: true,
    dsStartDate1: new Date(2021, 5, 7, 0, 0, 0),
    dsStartDate2: new Date(2021, 5, 22, 0, 0, 0),

    simplifiedProjects: true,
    simplifiedProjectsStartDate: new Date(2021, 5, 28, 0, 0, 0),
    termsAndConditionFileName: 'UCI_Terms&Conditions.pdf',
    preventStageChangeOnValidationKO: true,
    // requireAddStageConfirmationFinCes: true,
    technicalCertification: true,
    b2bYearChecklistSalDisabled: true,
    allowMultiUploadActions: true,
    workablePracticeEnabled: true,
    chatbot: {
      ...baseEnvironment.configuration.chatbot,
      enabled: true,
      chatbotAIModeEnabled: true,
      chatbotLinkToReportEnabled: true,
    },
    liveAssistantActive: true,
    holidaySkipEnabled: true,
    leadingProjectExistance: {
      active: true,
      startDate: new Date(2024, 1, 28, 18, 0, 0)
    }
  },
  enabledSSOOnlyForInternal: {
    active: true,
    domain: 'pwc.com'
  },
  recaptcha: '6LdSFMMpAAAAABK8Q9oX17BdXENqbgfr6bHx4qz7'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
