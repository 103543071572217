import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {RoutePath} from '../../enums/route-path.enum';
import {AuthorityCodeEnum} from '../../enums/security/authority-code.enum';
import {environment} from '../../../environments/environment';

@Component({
  template: ''
})
export class BaseComponent implements OnDestroy {
  subscriptions: Subscription[] = [];
  environment = environment;

  RoutePath = RoutePath;
  AuthorityCodeEnum = AuthorityCodeEnum;

  ngOnDestroy(): void {
    if (this.subscriptions == null) {
      return;
    }

    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  buildRoute(segments: string[]): string {
    return RoutePath.SLASH + segments.join(RoutePath.SLASH);
  }
}
